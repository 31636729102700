.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsGallery {
  height: 100%;
  min-height: 5em;
}
@media (max-width: 1023px ) {
  .TsGallery {
    height: auto;
  }
}
.TsGallery__inner {
  height: inherit;
}
.TsGallery .vueperslides {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.TsGallery .vueperslides__inner {
  height: inherit;
}
.TsGallery .vueperslides__parallax-wrapper {
  height: inherit !important;
}
.TsGallery .vueperslides__bullets {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.3rem;
  z-index: 2;
}
.TsGallery .vueperslides__bullet {
  transition: opacity 0.2s ease;
  display: flex;
  align-items: stretch;
  padding: 0.25rem;
  height: 1rem;
  width: 1rem;
}
.TsGallery .vueperslides__bullet span {
  border-radius: 50%;
  font-size: 0;
  line-height: 0;
  flex-grow: 1;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
.TsGallery .vueperslides__bullet--active {
  opacity: 1;
  padding: 0.125rem;
}
.TsGallery .vueperslides__bullet--active span {
  background-color: white;
}
.TsGallery .vueperslides__arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  transform: none;
}
.TsGallery .vueperslides__arrow--prev,
.TsGallery .vueperslides__arrow--next {
  background-repeat: no-repeat;
  background-position: center;
}
.TsGallery .vueperslides__arrow--prev {
  left: 0;
  background-image: url("../assets/images/icon--sliderArrows.svg");
  transform: rotate(180deg);
}
.TsGallery .vueperslides__arrow--next {
  right: 0;
  background-image: url("../assets/images/icon--sliderArrows.svg");
}
.TsGallery .vueperslides:hover .vueperSlides__arrow {
  opacity: 1;
}
.TsGallery .vueperslide {
  position: relative;
}
.TsGallery .vueperslide__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TsGallery .vueperslide .MhImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
