.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsSearchResults {
  height: 100%;
  width: 100%;
}
.TsSearchResults__groups {
  display: grid;
  grid-column-gap: var(--app-content-gridGap, 1rem);
  grid-row-gap: var(--app-content-gridGap, 1rem);
}
.TsSearchResults__groups > * {
  min-height: 0;
  min-width: 0;
}
.TsSearchResults__groupTitle .TsSubHeadline__text {
  display: flex;
  justify-content: space-between;
}
.TsSearchResults__groupTitle .TsSubHeadline__text span:last-child {
  display: none;
}
@media (min-width: 1023px ) {
  .TsSearchResults__groups {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1023px ) {
  .TsSearchResults__groups {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 750px ) {
  .TsSearchResults__groups {
    grid-template-columns: repeat(1, 1fr);
  }
}
.TsSearchResults__groups {
  padding-top: 0.5em;
}
.TsSearchResults__group {
  padding-bottom: 0.5em;
}
.TsSearchResults__groupTitle {
  padding-left: 0;
  border-bottom: 2px solid;
  margin-bottom: 0.5em;
}
.TsSearchResults .TsSubHeadline__text {
  font-family: 'Packer', monospace;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  padding-bottom: 9px;
}
@media (min-width: 1023px ) {
  .TsSearchResults__group {
    min-height: 10em;
  }
}
@media (max-width: 1023px ) {
  .TsSearchResults__group {
    min-height: 8em;
  }
}
@media (max-width: 750px ) {
  .TsSearchResults__group {
    min-height: initial;
  }
}
