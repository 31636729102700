.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsAkkordeon__itemTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1023px ) {
  .TsAkkordeon .coloredDot {
    height: 15px;
    width: 15px;
  }
}
.TsAkkordeon__itemHeader {
  cursor: pointer;
}
.TsAkkordeon__itemTitle {
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 1023px ) {
  .TsAkkordeon__itemTitle {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.TsAkkordeon__itemBodyInner {
  padding-top: 15px;
  padding-bottom: 30px;
}
@media (max-width: 1023px ) {
  .TsAkkordeon__itemBodyInner {
    padding-top: 13px;
    padding-bottom: 20px;
  }
}
.TsAkkordeon__itemImage {
  margin-bottom: 0.5em;
}
