























































































































































































































.MhMasonryGrid { // debug
	[showBorders4] & {
		background-color: fade( red, 10 );
		& > * { outline: 1px solid red; background-color: fade( red, 10 ); }
	}

	//&__trashItem { outline: 1px solid red; }
	//&__trashItemInner { background-color: fade( red, 10 ); }
}
.MhMasonryGrid { // layout
	display: grid;
	//grid-template-columns: repeat(2, 1fr);
}
.MhMasonryGrid { // styling
	//padding: 1em;
	//background-color: fade( red, 10 );
}
