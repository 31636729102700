.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsTeachingArchive {
  height: 100%;
  width: 100%;
}
.TsTeachingArchive .TsFilterbar {
  grid-column: span 4;
}
.TsTeachingArchive__viewSwitcher {
  position: absolute;
  top: 2em;
  right: 0;
}
.TsTeachingArchive__listItemLink {
  display: flex;
  justify-content: space-between;
}
.TsTeachingArchive__listItemDots {
  padding-left: 0.5em;
  display: flex;
}
@media (max-width: 1023px ) {
  .TsTeachingArchive .coloredDot {
    height: 15px;
    width: 15px;
  }
}
@media (max-width: 1023px ) {
  .TsTeachingArchive .TsPostHeader {
    margin-top: 15px;
  }
}
.TsTeachingArchive__listItemLink {
  padding-bottom: 10px;
  color: inherit;
}
@media (max-width: 1023px ) {
  .TsTeachingArchive__listItemLink {
    padding-bottom: 8px;
  }
}
@media (max-width: 1023px ) {
  .TsTeachingArchive__listItemTextTitle {
    font-size: 20px;
  }
}
.TsTeachingArchive__listItem + .TsTeachingArchive__listItem .TsTeachingArchive__listItemLink {
  padding-top: 10px;
}
@media (max-width: 1023px ) {
  .TsTeachingArchive__listItem + .TsTeachingArchive__listItem .TsTeachingArchive__listItemLink {
    padding-top: 8px;
  }
}
@media (max-width: 1023px ) {
  .TsTeachingArchive__listItemDots {
    padding-top: 3px;
  }
}
