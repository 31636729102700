.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsTextCards__inner {
  display: grid;
  grid-column-gap: var(--app-content-gridGap, 1rem);
  grid-row-gap: var(--app-content-gridGap, 1rem);
}
.TsTextCards__inner > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .TsTextCards__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
.TsTextCards__cardTextAbove {
  padding-bottom: 9px;
}
@media (max-width: 1023px ) {
  .TsTextCards__cardTextAbove {
    padding-bottom: 2px;
    font-size: 14px;
  }
}
.TsTextCards__cardLine {
  padding-bottom: 11px;
}
@media (max-width: 1023px ) {
  .TsTextCards__cardLine {
    padding-bottom: 5px;
  }
}
.TsTextCards__cardTextBelow {
  word-break: break-word;
}
