.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsNewsArchive__posts {
  display: grid;
  grid-column-gap: var(--app-content-gridGap, 1rem);
  grid-row-gap: var(--app-content-gridGap, 1rem);
}
.TsNewsArchive__posts > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .TsNewsArchive__posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
.TsNewsArchive__homeIntroTextWrapper {
  position: relative;
  height: 100vh;
}
@media (max-width: 750px ) {
  .TsNewsArchive__homeIntroTextWrapper {
    height: 50vh;
  }
}
@media (max-width: 750px ) {
  .TsNewsArchive__homeIntroTextWrapper {
    overflow: hidden;
  }
}
.TsNewsArchive__homeIntroText {
  padding-top: 23px;
  padding-bottom: 1em;
}
@media (max-width: 1023px ) {
  .TsNewsArchive__homeIntroText {
    font-size: 15px;
    line-height: 19px;
  }
}
@media (max-width: 750px ) {
  .TsNewsArchive__homeIntroText {
    padding-bottom: 3em;
  }
}
.TsNewsArchive__readMoreBtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 1em;
  padding-bottom: 1.25em;
  font-size: 10px;
  font-weight: bold;
  color: inherit;
  text-decoration: underline;
  background-color: white;
}
@media (min-width: 1023px ) {
  .TsNewsArchive__readMoreBtn {
    display: none;
  }
}
@media (max-width: 1023px ) {
  .TsNewsArchive__readMoreBtn {
    display: none;
  }
}
@media (max-width: 750px ) {
  .TsNewsArchive__readMoreBtn {
    display: block;
  }
}
.TsNewsArchive__readMoreBtn:before {
  position: absolute;
  top: -5em;
  left: 0;
  right: 0;
  height: 5em;
  content: "";
  background: linear-gradient(0deg, #f9f9f9 0%, rgba(255, 255, 255, 0) 100%);
}
.TsNewsArchive__readMoreBtn--readLess.TsNewsArchive__readMoreBtn:before {
  display: none;
}
