.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
[showBorders3] .TsFilterbar {
  background-color: rgba(255, 0, 0, 0.2);
}
.TsFilterbar__inner {
  display: flex;
}
.TsFilterbar__terms {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
}
.TsFilterbar__terms--isOpen {
  white-space: initial;
}
.TsFilterbar__fader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 7em;
}
.TsFilterbar__terms--isOpen .TsFilterbar__fader {
  display: none;
}
.TsFilterbar {
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.TsFilterbar__inner {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1em;
}
.TsFilterbar__fader {
  /*
		background: linear-gradient(
			90deg,
			@swatches[lightestBgGray] 0%,
			rgba(0,0,0,0) 100%,
		);
		*/
  background: linear-gradient(90deg, rgba(244, 244, 244, 0.05) 0%, #f4f4f4 100%);
}
.TsFilterbar__termBtn {
  display: inline-flex;
  padding: 0em 0.5em;
  margin-right: 0.35em;
  margin-bottom: 0.35em;
  height: 1.5em;
  align-items: center;
  transition: all 0.1s ease;
  border-radius: 0.35em;
  color: inherit;
  white-space: nowrap;
}
.TsFilterbar__termBtn--tag {
  border: 1px solid;
}
.TsFilterbar__termBtn--toggleBar {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  border: 1px solid;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  width: 1.5em;
}
.TsFilterbar__termBtn--toggleBar span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid rgba(255, 0, 0, 0);
  flex-grow: 1;
  height: 100%;
  background-image: url(../assets/images/icon--sliderArrows.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: invert(100%);
  transform: rotate(90deg);
}
.TsFilterbar__terms--isOpen + .TsFilterbar__termBtn--toggleBar {
  transform: rotate(180deg);
}
.TsFilterbar--hasActiveFilter .TsFilterbar__termBtn:not(.TsFilterbar__termBtn--isActive) {
  opacity: 0.35;
}
