.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.TsTeamList__itemTitle {
  display: flex;
  justify-content: space-between;
}
.TsTeamList__itemTitleDots {
  display: flex;
  height: 100%;
  align-items: flex-start;
}
@media (min-width: 1023px ) {
  .TsTeamList__itemImage {
    width: 30%;
  }
}
.TsTeamList__itemText {
  flex-grow: 1;
}
@media (max-width: 1023px ) {
  .TsTeamList .coloredDot {
    height: 15px;
    width: 15px;
  }
}
.TsTeamList__itemHeader {
  cursor: pointer;
}
.TsTeamList__itemTitleDots {
  padding-top: 0.3em;
}
.TsTeamList__itemTitle {
  padding-bottom: 10px;
}
@media (max-width: 1023px ) {
  .TsTeamList__itemTitle {
    padding-bottom: 8px;
  }
}
.TsTeamList__item + .TsTeamList__item .TsTeamList__itemTitle {
  padding-top: 10px;
}
@media (max-width: 1023px ) {
  .TsTeamList__item + .TsTeamList__item .TsTeamList__itemTitle {
    padding-top: 8px;
  }
}
.TsTeamList__itemBodyInner {
  padding-bottom: 15px;
  display: flex;
}
@media (max-width: 1023px ) {
  .TsTeamList__itemBodyInner {
    flex-direction: column;
  }
}
.TsTeamList__itemImage {
  margin-bottom: 10px;
  flex-shrink: 0;
}
@media (min-width: 1023px ) {
  .TsTeamList__itemImage {
    margin-right: 15px;
  }
}
@media (max-width: 1023px ) {
  .TsTeamList__itemTitleTextInstitution {
    font-size: 14px;
  }
}
@media (max-width: 1023px ) {
  .TsTeamList__itemText {
    font-size: 14px;
  }
}
