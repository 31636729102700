.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--yellow {
  --backgroundColor: #FFFF4D;
  background-color: #FFFF4D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--white {
  --backgroundColor: white;
  background-color: white;
}
.background--textBlack {
  --backgroundColor: #000;
  background-color: #000;
}
.background--lightBgGray {
  --backgroundColor: #dbdbdb;
  background-color: #dbdbdb;
}
.background--lightestBgGray {
  --backgroundColor: #f4f4f4;
  background-color: #f4f4f4;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--yellow {
  --color: #FFFF4D;
  color: #FFFF4D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--white {
  --color: white;
  color: white;
}
.color--textBlack {
  --color: #000;
  color: #000;
}
.color--lightBgGray {
  --color: #dbdbdb;
  color: #dbdbdb;
}
.color--lightestBgGray {
  --color: #f4f4f4;
  color: #f4f4f4;
}
.PageView__homeIntroText {
  height: 100%;
}
.PageView--postType-post .view__panel--left,
.PageView--postType-resources_post .view__panel--left,
.PageView--postType-teaching_post .view__panel--left,
.PageView--postType-activity_post .view__panel--left {
  border-right: 2px solid;
  padding: 15px;
}
@media (max-width: 1023px ) {
  .PageView--postType-post .view__panel--left,
  .PageView--postType-resources_post .view__panel--left,
  .PageView--postType-teaching_post .view__panel--left,
  .PageView--postType-activity_post .view__panel--left {
    padding: 13px;
  }
}
@media (max-width: 1023px ) {
  .PageView--postType-post .view__panel--right .TsGallery,
  .PageView--postType-resources_post .view__panel--right .TsGallery,
  .PageView--postType-teaching_post .view__panel--right .TsGallery,
  .PageView--postType-activity_post .view__panel--right .TsGallery {
    margin-bottom: 0.5em;
  }
}
.PageView .TsAdjacentLinks {
  padding-top: 1em;
}
